import React, { useState, useEffect } from "react";
import "./loginBody.css";
import axios from "axios";
import { Header } from "./Header";
import { Products } from "./Products";
import { LoginFooter } from "./loginFooter/loginFooter";
import { useNavigate } from "react-router-dom";
import { setID, setName, setCustomer } from "../../redux/slice/userSlice";
import { setAuth } from "../../redux/slice/authSlice";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import analticsLogo from "../../assets/AnalyticsLogo.svg";
// import visiblePassword from "../../assets/PasswordVisible.svg";
// import invisiblePassword from "../../assets/PasswordInvisible.svg";
import { toast ,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import for toast notifications
import VerifiedAnimation from '../Verify/VerifiedAnimation';

export const LoginBody = () => {
  localStorage.clear();
  sessionStorage.clear();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [username, setUsername] = useState(''); 
  // const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailExists, setEmailExists] = useState(true); // This will track if the email exists
const [emailError, setEmailError] = useState(""); // This will store the error message
const [animationDisplay,setAnimationDisplay]=useState(false);

  // New states for OTP timer and resend button functionality
  const [timer, setTimer] = useState(60);  // OTP timer
  const [isResendDisabled, setIsResendDisabled] = useState(true);  // Disable resend OTP initially
  const [isLoginView, setIsLoginView] = useState(true);  // Toggle login and OTP view
  const [errorMessage , setErrorMessage] = useState("");
  const [resetTimer, setResetTimer] = useState(false);  // New state flag to reset timer

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    
    const config = {
      css_url: "/app/embedded_signin_providers_only.css",
      signin_providers_only: true,
      forgot_password_css_url: "/app/fpwd.css",
    };
    const call = window.catalyst.auth.signIn("login", config);
    window.catalyst.auth.signIn("login", config);
    // setLoading(false);
  }, []);

  
  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsResendDisabled(false); // Enable Resend OTP button after 30 seconds
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const setHide=()=>{
    setErrorMessage("");
    setIsResendDisabled(true);
  }
  useEffect(() => {
    if (resetTimer) {
      setTimer(0);  // Reset the timer state
      setIsResendDisabled(false);  // Enable resend button
      setResetTimer(false);  // Reset the flag
    }
  }, [resetTimer]);
  
  // const handleSubmit = async (e) => {
  //   setLoader(true);
  //   e.preventDefault();
  //   var body = { loginId: username, otp: otp };
  //   const url =
  //     (process.env.REACT_APP_ENV === "DEVELOPMENT"
  //       ? process.env.REACT_APP_API_BASE_URL_DEV
  //       : process.env.REACT_APP_API_BASE_URL_PROD) + "login";
  //   const key =
  //     process.env.REACT_APP_ENV === "DEVELOPMENT"
  //       ? process.env.REACT_APP_API_KEY_DEV
  //       : process.env.REACT_APP_API_KEY_PROD;
  //   axios.defaults.headers["ZCFKEY"] = key;
  //   let response = await axios
  //     .post(url, body)
  //     .then(function (response) {
  //       if (response.data.status === 200) {
  //         if (response.data.contact_number) {
  //           dispatch(setID(response.data.contact_number));
  //           dispatch(setName(response.data.name));
  //           dispatch(setAuth(true));
  //           navigate("/app/Dashboard");
  //           localStorage.setItem("authenticated", true);
  //         } else {
  //           localStorage.setItem("authenticated", true);
  //           alert("WRONG CREDENTIALS");
  //         }
  //         setLoader(false);
  //       } else {
  //         alert("Wrong Email or Password!");
  //         setLoader(false);
  //       }
  //     })
  //     .catch(function (error) {
  //       alert("Wrong Email or Password!");
  //       setLoader(false);
  //     });
  // };
  const handleSubmit = async (e) => {
    setLoader(true);
    
    e.preventDefault();
    var body = { loginId: username, otp:otp.join("") }; // Include OTP in the body when submitting

    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_KEY_DEV
          : process.env.REACT_APP_API_KEY_PROD,
    };
    let url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV + "Signin"
        : process.env.REACT_APP_API_BASE_URL_PROD + "Signin";

    try {
      const response = await axios.post(url, body, { headers });
      
      if (response.data.status === 200 && response.data.message === "Logging In Successful" ) {
        if (response.data.contact_number) {
          dispatch(setID(response.data.contact_number));
          dispatch(setName(response.data.name));
          dispatch(setAuth(true));
          setHide();
          setAnimationDisplay(true);
          setResetTimer(0);
          setTimeout(()=>{navigate("/app/Dashboard");},2000);
          // navigate("/app/Dashboard");
          localStorage.setItem("authenticated", true);
     
        } else {
          // toast.error(response.data.message);
          
          setErrorMessage(response.data.message)
          
          
        }

        setLoader(false);
      
      } 
      else if(response.data.message === "Wrong OTP entered."){
        setErrorMessage("Incorrect OTP")
        setLoader(false);
      }
    } catch (error) {
     
      // toast.error("Wrong Email or OTP");
      setErrorMessage("Wrong Email or OTP")
      setLoader(false);      
    }
  };
  const handleSendOtp = async (e) => {
    
    setLoader(true);
    e.preventDefault();

    if (!username) {
      setEmailExists(false); // Set emailExists to false if no email is entered
      setEmailError("Please enter a valid email address.");
      // toast.error("Please enter a valid email address.");
      return;
    }

    const body = { loginId: username };

    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:
        process.env.REACT_APP_ENV === "DEVELOPMENT"
          ? process.env.REACT_APP_API_KEY_DEV
          : process.env.REACT_APP_API_KEY_PROD,
    };

    const url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV + "generateOtp"
        : process.env.REACT_APP_API_BASE_URL_PROD + "generateOtp";

    try {
      const response = await axios.post(url, body, { headers });

      if (response.data.status === 200 && response.data.message === "OTP sent successfully") {
        setIsLoginView(false);
        setTimer(60);
        setIsResendDisabled(true);
        setIsOtpSent(true);
        toast.success("OTP sent successfully to your email.");
      } else {
        toast.error(response.data.message);
        
      }
    } catch (error) {
      setEmailExists(false);
      toast.error("Incorrect Email!");
      setTimeout(()=>{ 
        setEmailExists(true); // Revert to valid email state
        // setUsername("")
      }
      // Clear the error message
    , 3000);
    } finally {
      setLoader(false);
    }
  };

  const handleResendOtp = (e) => {
    setErrorMessage("");

    if (timer === 0) {
      handleSendOtp(e);
    }
  };
  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
    if (index < 5 && e.target.value) {
      document.getElementById(`otp-input-${index + 1}`).focus(); // Focus next input
    }
  };
 
const otpInputs = document.querySelectorAll('.otp-input-box');

otpInputs.forEach((input, index) => {
  

  // Handle the backspace key to erase input and focus previous field
  input.addEventListener('keydown', (event) => {
    if (event.key === 'Backspace' && !input.value && index > 0) {
      // Focus the previous input if the current is empty
      const prevInput = otpInputs[index - 1];
      prevInput.focus();
    }
  });
});

  return (
    <div className="main-body">
      <Header />
      <ToastContainer/>
      {loader && <Loader />}
      <div className="login-window-container">
        <div className="login-window">
          <div className="logo-container">
            <img src={analticsLogo} className="analytics-logo" alt="Analytics Logo" />
          </div>

          <div className="verticle-line"></div>

          <div className="signup-container">
            <form className="signup" onSubmit={isOtpSent ? handleSubmit : handleSendOtp}>
              <div className="signup-title">{isOtpSent ? "Enter OTP" : "Sign In"}</div>
              {/* {isOtpSent && timer > 0 ? ( */}

              {!isOtpSent ? (
                <input
                type="text"
                name="username"
                value={username}
                placeholder={emailError || "Enter Email Id"} // Show the error message in the placeholder
                onChange={(e) => setUsername(e.target.value)}
                className={`signup-user ${!emailExists ? "error" : ""}`} // Apply the error class if email doesn't exist
                autoComplete="off"
                required={true}
              />
              
              ) : (<><div id="timer">
                <p>OTP is valid for {timer} Seconds only</p>
              </div>
                <div className="otp-input-container">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      className="otp-input-box"
                      required
                    />)
                    )}
                </div></>
              )}
              {isOtpSent && timer > 0 ? (
                   <div className="resend-container" >
                  <p
                    className="error-message"
                    style={{ visibility: !isResendDisabled && animationDisplay ? "hidden" : "visible" }}
                  >
                    {errorMessage}
                  </p></div> ) : (
                    <div className="resend-container">
                    <div>
                    <p
                      className="error-message"
                      style={{ visibility: isResendDisabled && !animationDisplay? "hidden" : "visible" }}
                    >
                      {errorMessage}
                    </p>
                    </div>
                    <div>
                    <button
                      id="resend-otp-button"
                      type="button"
                      onClick={handleResendOtp}
                      disabled={isResendDisabled}
                      style={{ visibility: isResendDisabled && !isOtpSent && !animationDisplay ? "hidden" : "visible" }}
                    >
                      Resend OTP
                    </button>
                    </div>
                  </div>
                  
              )}

            
              {isOtpSent ? (
                animationDisplay ? (<div className="verifiedAnimation">
                  <VerifiedAnimation />
                </div>
                  
                ) : (
                  <button
                  className="send-otp-btn"
                  type="submit"
                  disabled={isOtpSent && otp.some((digit) => !digit)}
                >Validate</button>
                )
              ) : (
                <button
                  className="send-otp-btn"
                  type="submit"
                  disabled={isOtpSent && otp.some((digit) => !digit)}
                >Send OTP</button>
              )}
      
            </form>

            <div className="signup-footer">
              <p>Privacy</p>
              <p>Terms & Conditions</p>
              <p>Help</p>
            </div>
          </div>
        </div>
      </div>
      <Products />
      <LoginFooter />
    </div>
  );
};
