import React from "react";
import "./VerifiedAnimation.css";

const VerifiedAnimation = () => {
  return (
    <div className="verified-container">
      <div className="verified-icon">
        <div className="verified-check">✔</div>
      </div>
    </div>
  );
};

export default VerifiedAnimation;
